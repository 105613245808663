<template>
    <div class="p-3">
        <h2>PIŠKOTKI NA PORTALU <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a></h2>
        Spletna stran <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> uporablja piškotke. S piškotki vas
        osebno ne identificiramo, uporabljamo jih samo za boljše delovanje spletišča.
        <br>
        Z uporabo tega spletnega mesta se strinjate, da spletno mesto <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        nastavi piškotke na vaši napravi.
        <h3 class="mt-3">Kaj so piškotki?</h3>
        Piškotek je kratko besedilo, ki ga spletna stran ob vašem obisku pošlje brskalniku. Tako vas spletna stran
        prepozna, si z njim zapomni podatke o vašem obisku in vam omogoči prijazno ter enostavnejšo spletno storitev.
        Brskanje po naši spletni trgovini je brez piškotkov nemogoče, saj prav piškotki omogočajo funkcije e-poslovanja
        in so temeljnega pomena za zagotavljanje uporabniku prijaznih spletnih storitev, ki izboljšujejo uporabnikovo
        izkušnjo.
        <br>
        Sam piškotek ne vsebuje ali zbira informacij, ki bi omogočali identifikacijo posameznika, če pa spletnemu mestu
        uporabniki te podatke posredujete sami, na primer z registracijo, se ti lahko povežejo s podatki, ki so
        shranjeni v piškotku. Če strežnik prebere piškotek skupaj s spletnim brskalnikom, je to lahko spletnemu mestu v
        pomoč pri izvajanju uporabniku prijaznejših storitev. Samo strežnik, ki je poslal piškotek, lahko prebere in
        uporablja ta piškotek.
        <br>
        Podatki, zbrani s pomočjo piškotka, sami po sebi ne omogočajo identifikacije uporabnika v smislu določb Zakona o
        varstvu osebnih podatkov (ZVOP-1), kar pomeni, da zgolj na podlagi tako zbranih podatkov ni mogoče ugotoviti
        imena in priimka in drugih identifikacijskih podatkov uporabnika.
        <br>
        Upravljalec strani preko uporabnikovega brskalnika pridobi podatke o uporabi spletnega mesta ter o nekaterih
        značilnostih uporabnika oziroma njegove opreme, s katero dostopa do spletnega mesta (npr. ločevanje med
        uporabniki, datum zadnjega obiska, tip spletnega brskalnika ipd.).
        <h3 class="mt-3">Nadzor nad piškotki</h3>
        Shranjevanje piškotkov je pod popolnim nadzorom vašega brskalnika. Z izbiro ustreznih opcij v vašem brskalniku
        lahko shranjevanje piškotkov po želji kadarkoli omejite ali onemogočite, piškotke na vaši napravi pa lahko
        kadarkoli trajno zbrišete.
        <br>
        Za informacije o nastavitvah za piškotke izberite spletni brskalnik, ki ga uporabljate:
        <ul>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=sl-sl&p=cpn_cookies"
                   target="_blank">Chrome</a></li>
            <li><a
                href="https://support.mozilla.org/sl/kb/kako-omogocite-onemogocite-piskotke?redirectlocale=sl&redirectslug=Kako+aktivirate+in+deaktivirate+pi%C5%A1kote"
                target="_blank">Firefox</a></li>
            <li><a
                href="http://www.windows10update.com/2015/07/windows-10-tutorials-128-allow-or-block-cookies-in-microsoft-edge/"
                target="_blank">Edge</a> (stran v angleškem jeziku)
            </li>
            <li><a href="http://windows.microsoft.com/sl-si/windows7/how-to-manage-cookies-in-internet-explorer-9"
                   target="_blank">Internet Explorer 9</a></li>
            <li><a href="http://windows.microsoft.com/sl-si/windows-vista/block-or-allow-cookies" target="_blank">Internet
                Explorer 7 in 8</a></li>
            <li><a href="http://www.opera.com/help/tutorials/security/cookies/" target="_blank">Opera</a> (stran v
                angleškem jeziku)
            </li>
            <li><a href="https://support.apple.com/kb/PH17191?locale=sl_SI" target="_blank">Safari</a> (stran v
                angleškem jeziku)
            </li>
        </ul>
        Z izključitvijo piškotkov spletno mesto <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> ne bo
        delovalo, saj prav piškotki omogočajo funkcije e-poslovanja.
        <h3 class="mt-3">Piškotki na naših straneh:</h3>
        adfb689897b2b5255adcaee72945c791 – podatki o upravljalcu strani. Čas piškotka: do konca seje.
        <br>
        fa53b91ccc1b78668d5af58e1ed3a485 – podatki o aktualni prijavi. Čas piškotka: do konca seje.
        <h3 class="mt-3">Upravljalec</h3>
        Upravljalec spletne strani je podjetje SMRK, Mojca Tomišić, s.p. Lahko nas kontaktirate na telefonski številki
        040/612-870 ali elektronsko: info@smrk.si.


    </div>
</template>

<script lang="">
export default {
  name: "Cookies",
  components: {},
  props: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  computed: {},
  filters: {},
  watch: {}
}

</script>

<style scoped>

</style>
